<template>
  <el-container>
    <el-header>
      优惠设置
    </el-header>
    <el-main class="main">

      <div class="content">
        <div class="youhui-set">
          <!-- <router-link to="/maling-set" class="cell-y">
            <div class="title">
              抹零设置
            </div>
            <div class="value">
              <span class="el-icon-arrow-right"></span>
            </div>
          </router-link>
          <router-link to="/discount-set" class="cell-y">
            <div class="title">
              优惠折扣设置
            </div>
            <div class="value">
              <span class="el-icon-arrow-right"></span>
            </div>
          </router-link>
          <router-link to="/goods-to-price-set" class="cell-y">
            <div class="title">
              商品改价设置
            </div>
            <div class="value">
              <span class="el-icon-arrow-right"></span>
            </div>
          </router-link> -->
          <router-link to="/Points" class="cell-y">
            <div class="title">
              积分比例设置
            </div>
            <div class="value">
              <span class="el-icon-arrow-right"></span>
            </div>
          </router-link>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    methods: {

    }
  }

</script>

<style lang="less" scoped>
  .el-header {
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;
  }

  .main {
    background-color: #f9f9f9;
  }

  .content {
    padding: 20px;

    .youhui-set {

      .cell-y {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid #f2f2f2;
        color: #666;
        background-color: #fff;
        margin-bottom: 20px;

        .value {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .el-icon-arrow-right {
            margin-left: 20px;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
  }

</style>
